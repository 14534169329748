/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, {useState} from 'react';

import {useLocation} from '@reach/router';
import {BlockContentProps} from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
// eslint-disable-next-line import/no-unresolved
import {SanityImageSource} from '@sanity/image-url/lib/types/types';
import classNames from 'classnames';
import {trackEvent} from 'src/analytics';
import {
  Accordion,
  AvatarCard,
  AvatarList,
  Body,
  Button,
  ButtonRow,
  Choice,
  Datum,
  Heading,
  HeadingWithImage,
  Hero,
  Image,
  ListWithHeading,
  Membership,
  NavigationBar,
  NavRow,
  People,
  Price,
  PriceRow,
  Quote,
  Row,
  ShortDescription,
  Slider,
  Spacer,
  Testimonials,
  textModule,
  TextWithImage,
  themes,
  Video,
} from 'src/components/former-chief-marketing-web';
import {IFrame, IFrameProps} from 'src/components/page-builder/IFrame';
import {HeaderNav, Input, RichContent, RichContentBlockArray, Select} from 'src/components/shared';
import {Footer} from 'src/components/shared/Footer';
import {makeTailwindBasicThemeClasses} from 'src/utils/makeTailwindBasicThemeClasses';
import {sanityClient} from 'src/utils/sanity';

import {LeadForm} from '../leadForm/components/LeadForm';

export const addChildComponents = (components: Array<{_type: string}>): Array<React.ReactNode> =>
  components
    ?.map((component: {_type: string}) => {
      if (component && pageBuilderMap[component._type]) {
        return pageBuilderMap[component._type]({...component, isChildComponet: true});
      }
      return null;
    })
    .filter(element => element != null);

/**
 * @description the {@link pageBuilderMap} gets used by {@link CustomBuiltSanityPage} to render the page builder components from Sanity data. It maps the _type of Sanity Page Builder documents to a function that renders that component.
 *
 */
export const pageBuilderMap: {[key: string]: (props: any) => React.ReactNode} = {
  pageBuilderAccordionGroup: ({children}) => {
    const location = useLocation();
    const activeId = location.hash.slice(1); // if there is a hash in the url, the li with that id will be open upon navigation

    return (
      <ul>
        {children.map(
          (list: {
            _key: string;
            heading?: string;
            theme: string;
            topBorderLine: boolean;
            body: [];
            description: RichContentBlockArray;
          }) => {
            // default to heading in kebab case
            const id = list.heading
              ?.toLowerCase()
              .trim()
              .replace(/[^A-Za-z0-9\s]/g, '')
              .replace(/\s+/g, '-');
            const [open, setOpen] = useState(activeId === id);

            return (
              <Accordion className={themes[list.theme]} key={list._key}>
                <li data-expanded={open ? true : undefined} id={id} onClick={() => setOpen(!open)}>
                  {list.heading ? (
                    <h3 className={list.topBorderLine && textModule.withLineAbove}>{list.heading}</h3>
                  ) : null}
                  <p>{list.body}</p>
                  <RichContent blocks={list.description} serializers={serializers} />
                </li>
              </Accordion>
            );
          },
        )}
      </ul>
    );
  },
  pageBuilderBody: ({fontSize, description, theme, isBlock}) => {
    return (
      <Body size={fontSize} theme={theme} block={isBlock}>
        {!!description && <RichContent blocks={description} serializers={serializers} />}
      </Body>
    );
  },
  pageBuilderAvatarCard: ({alt, bio, company, image, jobTitle, url, urlName, variant}) => {
    return (
      <AvatarCard
        alt={alt}
        bio={bio}
        company={company}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        image={imageUrlBuilder(sanityClient).image(image).url()}
        title={jobTitle}
        url={url}
        urlName={urlName}
        variant={variant}
      />
    );
  },
  pageBuilderAvatarList: ({children, large, variant}) => {
    return (
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      <AvatarList large={large} variant={variant}>
        {addChildComponents(children)}
      </AvatarList>
    );
  },
  pageBuilderButton: ({_key, buttonText, download, href, priority, small, target, variant, width}) => {
    return (
      <Button
        download={download}
        key={_key}
        priority={priority}
        small={small}
        target={target}
        variant={variant}
        width={width}
        onClick={() => {
          trackEvent({
            eventName: `${buttonText} clicked`,
            category: 'Page Builder Element',
            elementKey: _key,
            elementText: buttonText,
            url: window.location.href,
          });
        }}
      >
        {href ? <a href={href}>{buttonText}</a> : buttonText}
      </Button>
    );
  },
  pageBuilderButtonRow: ({theme, spacing, verticalSpacing, height, align, children}) => (
    <Row align={align} theme={theme} height={height} spacing={spacing} verticalSpacing={verticalSpacing}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ButtonRow theme={theme}>{addChildComponents(children)}</ButtonRow>
    </Row>
  ),
  pageBuilderChoice: ({isMulti, label, name, onChange, options, value, variant}) => (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    <Choice
      isMulti={isMulti}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      value={value}
      variant={variant}
    />
  ),
  pageBuilderDatum: ({compact, data, description, divider, stack, variant}) => (
    <Datum compact={compact} data={data} description={description} divider={divider} stack={stack} variant={variant} />
  ),
  pageBuilderFooter: ({currentFooter, subscribe}) =>
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    currentFooter ? <Footer /> : <Footer subscribe={subscribe} />,
  pageBuilderHeading: ({align, block, children, size, theme, variant}) => {
    return (
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      <Heading align={align} block={block} size={size} theme={theme} variant={variant}>
        {children}
      </Heading>
    );
  },
  pageBuilderHeadingWithImage: ({
    align,
    children,
    size,
    theme,
    variant,
    image,
    imageAlign,
    containerWidth,
    imageMaxWidth,
    imageHeight,
    imageWidth,
    paddingTop,
    paddingBottom,
  }) => {
    return (
      <HeadingWithImage
        align={align}
        size={size}
        theme={theme}
        variant={variant}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        src={imageUrlBuilder(sanityClient).image(image).url()}
        imageAlign={imageAlign}
        containerWidth={containerWidth}
        imageMaxWidth={imageMaxWidth}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
      >
        {children}
      </HeadingWithImage>
    );
  },
  pageBuilderHero: ({
    children,
    eyebrow,
    eyebrowVariant,
    heading,
    headingSize,
    headingVariant,
    image,
    imagePosition,
    imageWidth,
    inlay,
    reverse,
    roundImage,
    theme,
  }) => {
    return (
      <Hero
        eyebrow={eyebrow}
        eyebrowVariant={eyebrowVariant}
        heading={heading}
        headingSize={headingSize}
        headingVariant={headingVariant}
        image={imageUrlBuilder(sanityClient).image(image).url()}
        imagePosition={imagePosition}
        imageWidth={imageWidth}
        inlay={inlay}
        reverse={reverse}
        roundImage={roundImage}
        theme={theme}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {addChildComponents(children)}
      </Hero>
    );
  },
  pageBuilderIFrame: (props: IFrameProps) => {
    return <IFrame {...props} />;
  },
  pageBuilderImage: ({
    alt,
    children,
    framed,
    height,
    image,
    inlay,
    logo,
    roundBottomLeft,
    roundBottomRight,
    roundTopLeft,
    roundTopRight,
    theme,
    width,
  }) => (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    <Image
      alt={alt}
      framed={framed}
      height={height}
      inlay={inlay}
      logo={logo}
      roundBottomLeft={roundBottomLeft}
      roundBottomRight={roundBottomRight}
      roundTopLeft={roundTopLeft}
      roundTopRight={roundTopRight}
      src={imageUrlBuilder(sanityClient).image(image).url()}
      theme={theme}
      width={width}
    >
      {addChildComponents(children)}
    </Image>
  ),
  pageBuilderInput: ({label, max, name, onChange, placeholder, type, value, variant}) => (
    <Input
      name={name}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      label={label}
      value={value}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      variant={variant}
      max={max}
    ></Input>
  ),
  pageBuilderLeadFormSection: ({leadForm, theme, isChildComponet}) => {
    const themeClasses = makeTailwindBasicThemeClasses(theme);

    /**
     * Why 720px? List With Heading, whose style we built this against, has a "Width Percent" option, that isn't used for any of our use cases yet.
     *
     * That "Width Percent" option says as follows:
     * > _"100% is the full width of the page (except for existing padding). If left empty, the content will be set to a max width of 720px.""_
     *
     * So we're just taking that default of 720 and running with it for now.
     * If we need to support "width percent" or other features in the future, we'll deal with it then
     */
    const DEFAULT_LIST_WITH_TITLE_MAX_WIDTH = 720;

    // Also matches the "List with Heading" component
    const responsivePaddingClasses = ['px-10', 'pagebuilder-sm:px-15', 'pagebuilder-md:px-20'];

    return (
      <div
        className={classNames(
          'lead-form-section-root',
          'pb-8',
          'flex',
          'justify-center',
          isChildComponet ? null : responsivePaddingClasses,
          themeClasses,
        )}
      >
        <div
          className="lead-form-wrapper"
          style={{
            maxWidth: DEFAULT_LIST_WITH_TITLE_MAX_WIDTH,
            width: '90vw',
          }}
        >
          <LeadForm {...leadForm} />
        </div>
      </div>
    );
  },
  pageBuilderLink: ({href, linkText, _key}) => (
    <a
      href={href}
      onClick={() => {
        trackEvent({
          eventName: `${linkText} clicked`,
          category: 'Page Builder Element',
          elementKey: _key,
          elementText: linkText,
          url: window.location.href,
        });
      }}
    >
      {linkText}
    </a>
  ),
  pageBuilderListItem: ({heading, listItems, _key}) => {
    return (
      <ul>
        <li key={_key}>
          {heading ? <h3>{heading}</h3> : null}
          <RichContent blocks={listItems} serializers={serializers} />
        </li>
      </ul>
    );
  },
  pageBuilderListWithHeading: ({
    heading,
    headingSize = 'xl',
    headingVariant,
    description,
    cta,
    image,
    imageWidth,
    imageHeight,
    roundImage,
    children,
    reverse,
    inlay,
    fullWidth,
    widthPercent,
    textAlign,
    inlayHeight,
    theme,
  }) => {
    return (
      <ListWithHeading
        description={description}
        heading={heading}
        headingSize={headingSize}
        headingVariant={headingVariant}
        image={imageUrlBuilder(sanityClient).image(image).url()}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        inlay={inlay}
        inlayHeight={inlayHeight}
        reverse={reverse}
        roundImage={roundImage}
        textAlign={textAlign}
        theme={theme}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        cta={cta?.children ? pageBuilderMap.pageBuilderButtonRow(cta.children) : null}
        fullWidth={fullWidth}
        widthPercent={widthPercent}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {addChildComponents(children || [])}
      </ListWithHeading>
    );
  },
  pageBuilderMembership: ({children, heading, headingSize, inlays, theme, variant}) => (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    <Membership heading={heading} headingSize={headingSize} inlays={inlays} theme={theme} variant={variant}>
      {addChildComponents(children)}
    </Membership>
  ),
  pageBuilderNavigationBar: ({currentNav, left, right}) =>
    currentNav ? (
      <HeaderNav />
    ) : (
      <NavigationBar
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        left={<NavRow>{addChildComponents(left)}</NavRow>}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        right={<NavRow>{addChildComponents(right)}</NavRow>}
      />
    ),
  pageBuilderPeople: ({
    eyebrow,
    description,
    eyebrowVariant,
    heading,
    headingSize,
    headingVariant,
    people,
    reverse,
    theme,
  }) => {
    return (
      <People
        eyebrow={eyebrow}
        eyebrowVariant={eyebrowVariant}
        heading={heading}
        headingSize={headingSize}
        headingVariant={headingVariant}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        people={pageBuilderMap.pageBuilderAvatarList(people)}
        reverse={reverse}
        theme={theme}
      >
        {description ? <RichContent blocks={description} serializers={serializers} /> : null}
      </People>
    );
  },
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  pageBuilderPrice: ({price, type, variant}) => <Price price={price} type={type} variant={variant} />,
  pageBuilderPriceRow: ({children, variant, disclaimer}) => (
    <PriceRow variant={variant} disclaimer={disclaimer}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {addChildComponents(children)}
    </PriceRow>
  ),
  pageBuilderQuote: ({
    alt,
    reverse,
    authorColorVariant,
    authorTitle,
    company,
    href,
    label,
    name,
    quote,
    large,
    quoteColorVariant,
  }) => (
    <Quote
      alt={alt}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      authorColorVariant={authorColorVariant}
      authorTitle={authorTitle}
      company={company}
      href={href}
      label={label}
      large={large}
      name={name}
      quote={quote}
      quoteColorVariant={quoteColorVariant}
      reverse={reverse}
    />
  ),
  pageBuilderRow: ({
    align,
    children,
    colorVariant,
    grid,
    height,
    noSpacingBottom,
    noSpacingTop,
    spacing,
    theme,
    verticalSpacing,
  }) => (
    <Row
      grid={grid}
      align={align}
      theme={theme}
      height={height}
      spacing={spacing}
      variant={colorVariant}
      noSpacingTop={noSpacingTop}
      noSpacingBottom={noSpacingBottom}
      verticalSpacing={verticalSpacing}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {addChildComponents(children)}
    </Row>
  ),
  pageBuilderSelect: ({name, value, label, options, onChange, variant}) => (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    <Select name={name} value={value} label={label} options={options} onChange={onChange} variant={variant} />
  ),
  pageBuilderShortDescription: ({
    bodySize,
    bodyText,
    className,
    heading,
    headingSize,
    headingVariant,
    inlay,
    reverse,
    theme,
  }) => {
    return (
      <ShortDescription
        className={className}
        heading={heading}
        headingSize={headingSize}
        headingVariant={headingVariant}
        inlay={inlay}
        reverse={reverse}
        theme={theme}
      >
        <Body size={bodySize}>{bodyText}</Body>
      </ShortDescription>
    );
  },
  pageBuilderSlider: ({theme, variant, children, perPage, spacing, height, showControls, autoplay}) => {
    return (
      <Slider
        autoplay={autoplay}
        height={height}
        perPage={perPage}
        showControls={showControls}
        spacing={spacing}
        theme={theme}
        variant={variant}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {addChildComponents(children)}
      </Slider>
    );
  },
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  pageBuilderSpacer: ({size}) => <Spacer size={size} />,
  pageBuilderTestimonials: props => (
    <Testimonials {...props}>
      {props.testimonials?.map(
        (testimonial: {
          _key: string;
          alt: string;
          avatar: {asset: SanityImageSource};
          className: string;
          company: string;
          href: string;
          label: string;
          large: boolean;
          logo: any;
          name: string;
          quote: string;
          quoteColorVariant: string;
          reverse: boolean;
          title: string;
        }) => {
          return (
            <Quote
              alt={testimonial.alt}
              avatar={imageUrlBuilder(sanityClient).image(testimonial.avatar.asset).url()}
              className={testimonial.className}
              company={testimonial.company}
              href={testimonial.href}
              key={testimonial._key}
              label={testimonial.label}
              large={testimonial.large}
              logo={testimonial.logo}
              name={testimonial.name}
              reverse={testimonial.reverse}
              title={testimonial.title}
              variant={testimonial.quoteColorVariant}
            >
              {testimonial.quote}
            </Quote>
          );
        },
      )}
    </Testimonials>
  ),
  pageBuilderTextWithImage: ({
    alt,
    extraLineHeight,
    heading,
    headingSize,
    headingVariant,
    image,
    imageHeight,
    imageMaxWidthPercent,
    imagePosition,
    imageWidth,
    padding,
    reverse,
    text,
    textBlockMaxWidth,
    theme,
    widthImage,
    widthText,
    withLine,
  }) => {
    return (
      <TextWithImage
        alt={alt}
        extraLineHeight={extraLineHeight}
        heading={heading}
        headingSize={headingSize}
        headingVariant={headingVariant}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        image={imageUrlBuilder(sanityClient).image(image).url()}
        imageHeight={imageHeight}
        imageMaxWidthPercent={imageMaxWidthPercent}
        imagePosition={imagePosition}
        imageWidth={imageWidth}
        padding={padding}
        reverse={reverse}
        textBlockMaxWidth={textBlockMaxWidth}
        theme={theme}
        widthImage={widthImage}
        widthText={widthText}
        withLine={withLine}
      >
        <RichContent blocks={text} serializers={serializers} extraListPadding={false} />
      </TextWithImage>
    );
  },
  pageBuilderVideo: ({framed, loop, muted, poster, theme, url}) => (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    <Video framed={framed} loop={loop} muted={muted} poster={poster} theme={theme} url={url} />
  ),
};

export const ListComponent: any = (props: any) =>
  props.type === 'number' ? <ol>{props.children}</ol> : <ul>{props.children}</ul>;

const serializers: BlockContentProps['serializers'] = {
  list: ListComponent,
  types: {
    block: ({children, node}) => {
      if (children.length === 1 && children[0] === '') {
        return <br />;
      }
      switch (node.style) {
        default:
          return <p>{children}</p>;
      }
    },
  },
};
